.hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: auto;
  font-size: 17px;
  text-align: center;
  align-items: center;
  color: white;
  padding-top: 100px;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info {
  background-image: url("background.png");
}

.speakerInfo {
  background: #6222aa;
}

.info > div > p:first-of-type {
  font-size: 22px;
  padding-bottom: 10px;
}

.info > div > p:last-of-type {
  font-size: 14px;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.icons img {
  width: 50px;
  height: auto;
  cursor: pointer;
  margin: 10px;
}

.mozaic {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.footer {
  margin-top: 10px;
  text-align: center;
}

.footer > a {
  color: #1ab4e4;
}

@media (max-width: 500px) {
  .info {
    padding-left: 5%;
    padding-right: 5%;
  }
}
