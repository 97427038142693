header p:first-of-type {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 20px;
}

header p:last-of-type {
  font-size: 40px;
  margin-top: -10px;
  padding-top: 0;
}

header {
  margin-top: 2%;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 500px) {
  header {
    margin-left: 5%;
    margin-right: 5%;
  }

  header p:first-of-type {
    margin-bottom: 0;
    font-size: 12px;
  }

  header p:last-of-type {
    font-size: 25px;
  }

  header img {
    width: 100px;
    height: auto;
  }
}
