.item {
  height: 300px;
  width: 330px;
  border: solid #1ab4e4 5px;
  margin: 10px;
  padding: 10px;
}

.hovereditem {
  height: 300px;
  width: 330px;
  background-color: #1ab4e4;
  margin: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 30px;
}

.details {
  padding-bottom: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.details .photoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  float: left;
}

.details .time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.details .photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding-right: 0;
}

.details img {
  width: 17px;
  height: auto;
  padding-right: 5px;
}

.details > h3,
.item > h3 {
  font-size: 22.5px;
  color: #1ab4e4;
  text-align: center;
}

.photoContainer > h4 {
  font-size: 18px;
  margin-left: 10px;
  margin-bottom: 0;
}

.hovereditem > a {
  font-size: 25px;
  color: white;
  text-decoration: none;
}

@media (max-width: 900px) {
  .item {
    height: auto;
  }
}
