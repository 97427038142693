.pastMeetups {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 50px;
  margin-bottom: 10px;
}

.pastMeetups > h2 {
  font-size: 45px;
  font-weight: bold;
  color: #6222aa;
}

.pastMeetups .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 900px) {
  .pastMeetups {
    margin-left: 5%;
    margin-right: 5%;
  }

  .pastMeetups > h2 {
    font-size: 25px;
  }

  .pastMeetups .container {
    display: flex;
    justify-content: center;
  }
}
