.speakerLink {
  color: white;
  background-color: #1ab4e4;
  width: 260px;
  height: 60px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.speakerLink > a {
  text-decoration: none;
  font-weight: bold;
  color: white;
}
